export default function pageBuilder({ pageUrl, fetch = () => ({}) }) {
  return {
    data() {
      return {
        page: {},
      }
    },
    computed: {
      blocks() {
        return (this.page?.blocks || []).map(block => {
          const { id, __component, layout, ...props } = block
          return {
            component: __component.split('.').reverse()[0],
            layout,
            props,
          }
        })
      },
    },
    watch: {
      async '$i18n.locale'() {
        const data = await this.$options.asyncData(this.$root.$options.context)
        this.page = data.page
      },
    },
    async asyncData(ctx) {
      const id = `page/${typeof pageUrl === 'string' ? pageUrl : pageUrl(ctx)}`
      const content = ctx.store.state.modules.content
      const page = content[content.locale][id] || (await ctx.store.dispatch('modules/content/getContent', { id }))
      ctx.store.dispatch('modules/content/setPage', id)

      const isEmptyPage = Object.keys(page || {}).length === 0
      if (isEmptyPage) {
        ctx.error({ statusCode: 404, message: 'Page not found' })
      }

      return { page, ...(await fetch(ctx)) }
    },
    methods: {
      async fetches() {
        return {}
      },
    },
  }
}
