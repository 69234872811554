<template>
  <Builder :blocks="blocks" :context="context" />
</template>

<script>
import Builder from '../../components/builder/Builder'
import pageBuilder from '../../components/mixins/pageBuilder.js'

const mixinPageBuilder = pageBuilder({
  pageUrl: 'clubs',
})

export default {
  name: 'ClubsList',
  components: {
    Builder,
  },
  mixins: [mixinPageBuilder],
  props: ['context'],
  meta: {
    theme: 'dark',
  },
}
</script>
